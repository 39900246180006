<template>
    <admin-dashboard-layout>
        <v-container>
            <v-row style="flex-wrap: nowrap">
                <v-flex shrink>
                    <v-card
                        max-width="450"
                        min-height="450"
                        class="mx-auto"
                    >
                        <v-toolbar
                            dark flat
                        >
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>

                            <v-toolbar-title>Inbox</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-list two-line>
                            <template >
                                <div v-for="(item) in chats" :key="`chat`+item.id">
                                    <chat-list-item :chat="item" @set-active-chat="setActiveChat(item)" />
                                </div>
                            </template>
                        </v-list>
                    </v-card>
                </v-flex>
                <v-flex grow>
                    <v-card height="100%" max-width="1024">
                        <div style=" height:100%;" class="d-flex flex-column">
                            <v-flex shrink>
                                <v-toolbar
                                    dark
                                    flat
                                >
                                    <v-toolbar-title v-if="activeChat.users  &&  otherUser.doctor">
                                        Chat with
                                        <router-link :to="{name: 'doctors.show', params: {city: otherUser.doctor.city.toLowerCase(), slug: otherUser.doctor.slug}}">
                                            <v-avatar size="32" class="ml-5"><v-img :src="otherUser.avatar_url"></v-img></v-avatar> {{otherUser.name}}
                                        </router-link>
                                    </v-toolbar-title>
                                    <v-spacer></v-spacer>

                                    <v-btn icon @click="openMeetModal">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </v-flex>
                            <v-flex grow>
                                <v-card-text style="height: 100%">
                                    <div v-if="activeChat && activeChat.messages"  id="chat-scroll" class="custom-scrollbar"  style="overflow-y: scroll; max-height: 75vh; height:90%;">
                                        <ul>
                                            <li :class="getMessageClass(message)" v-for="message in activeChatMessages" :key="message.id">
                                                {{message.text}}
                                            </li>
                                        </ul>
                                    </div>
                                </v-card-text>
                            </v-flex>

                            <v-card-actions>
                                <v-textarea height="60"
                                            outlined
                                            v-model="newMessageText"
                                            append-icon="mdi-send"
                                            @click:append="handleSendChatMessage"
                                />
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-flex>
            </v-row>
        </v-container>
    </admin-dashboard-layout>
</template>

<script>
import ChatListItem from "../../../components/ChatListItem";
import Chat from "../../../models/Chat";
import Message from "../../../models/Message";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";


export default {
    name: "Messages",
    components: {AdminDashboardLayout, ChatListItem},
    computed: {
        activeChatMessages() {
            let sorted = this.$_.clone(this.activeChat.messages)
            sorted = sorted.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
            return sorted
        },
        otherUser() {
            let authId = this.$auth.user().id;
            return this.activeChat.users.find(u => u.id !== authId);
        },
    },
    data: function () {
        return {
            showMeetModal: false,
            activeChat: {message: []},
            newMessageText: '',
            chats: [],
        }
    },
    methods: {
        async handleSendChatMessage() {
            let message = new Message({
                chat_id: this.activeChat.id,
                text: this.newMessageText,
            })
            await message.save()
            this.newMessageText = '';
            this.activeChat = await Chat.find(this.activeChat.id)
        },
        async setActiveChat(item) {
            this.activeChat = await Chat.find(item.id)
        },
        async openMeetModal() {
            //    let start = await wrappedAppointmentsStartRequest({id: 2})
            //   console.log(start);
            this.showMeetModal = true
        },
        getMessageClass(message) {
            if (!message.user_id) {
                return  'service'
            }
            return message.user_id === this.$auth.user().id ? 'me' :'him'
        }
    },
    async mounted() {
        this.chats = await Chat.get()

        window.Echo.private('test-channel')
            .listen('ChatMessageCreated', (e) => {
                if (e.message.user_id === this.$auth.user().id) {
                    return
                }
                this.activeChat.messages.push(e.message)
                this.$forceUpdate()
            });
    },
    watch: {
        activeChat() {
            this.$nextTick(function () {
                let element = document.getElementById('chat-scroll');
                element.scrollTo({
                    top: element.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        }
    }
}
</script>

<style scoped lang="scss">
#chat-scroll {
    ul{
        list-style: none;
        margin: 0;
        padding: 0;

    }

    ul li{
        display:inline-block;
        clear: both;
        padding: 20px;
        max-width: 55%;
        border-radius: 30px;
        margin-bottom: 2px;
    }

    .him{
        background: #eee;
        float: left;
    }

    .service {
        float: left;
        background: #898787;
    }

    .me{
        float: right;
        background: #0084ff;
        color: #fff;
    }

    .him + .service + .me{
        border-bottom-right-radius: 5px;
    }

    .me + .me{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    // scrollbar
    .custom-scrollbar::-webkit-scrollbar {
        width: 7px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: #ECF6FF;
        border-radius: 4px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #BED6EB;
        border-radius: 4px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #5b6873;
    }
}

</style>
