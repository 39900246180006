<template>
    <v-list-item
        @click="$emit('set-active-chat')"
        :key="otherUser.email"
    >
        <v-list-item-avatar>
            <v-img :src="avatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title v-html="otherUser.email"></v-list-item-title>
            <v-list-item-subtitle v-html="otherUser.email"></v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: ['chat'],
    name: "ChatListItem",
    computed: {
        otherUser() {
            let authId = this.$auth.user().id;
            return this.chat.users.find(u => u.id !== authId);
        },
        avatar() {
            if (this.otherUser.doctor) {
                return this.otherUser.doctor.avatar_url
            }
            if (this.otherUser.owner) {
                return this.otherUser.owner.avatar_url
            }
            return  ''
        }
    },
    data: function () {
        return {}
    },
}
</script>

<style scoped>

</style>
